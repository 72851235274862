<template>
    <div>
        <v-row>
            <v-col v-for="item of records" :key="item.id" cols="12">
                <v-card flat outlined class="rounded-lg">
                    <v-card-text>
                        <p v-html="item.note_value" class="text--primary"></p>
                        <p class="text-right">{{item.create_time}}</p>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <div class="text-center mt-8" v-if="records_total_page!=1">
            <v-pagination v-model="q.page" :length="records_total_page" :total-visible="7"></v-pagination>
        </div>
    </div>
</template>

<script>
    // @ is an alias to /src
    //import HelloWorld from '@/components/HelloWorld.vue'
    import showdown from "showdown"

    export default {
        name: 'Home',
        components: {
            //HelloWorld
        },
        data:function(){
            return {
                records:[],
                alert: {
                    visiable: false,
                    msg: "",
                    color: "red darken-2"
                },
                q: {
                    page: 1,
                    total: 10
                },
                records_total_page:0,
            }
        },
        methods:{
            getData() {
                let that = this
                this.axios.get(this.$store.state.base_url + "/note/list?page=" + this.q.page).then(function (
                    response) {
                    if (response.data.code == 200) {
                        let records = response.data.data.records
                        var converter = new showdown.Converter(); 
                        converter.setFlavor('github');
                        records.forEach(element => {
                            element.note_value = converter.makeHtml(element.note_value)
                        });
                        that.records = records
                        that.q.total = response.data.data.total
                        that.records_total_page = Math.ceil(that.q.total / 10)
                    } else {
                        that.alert.msg = "获取数据失败"
                        that.alert.color = "red darken-2"
                        that.alert.visiable = true
                    }

                }).catch(function (error) {
                    that.alert.msg = error
                    that.alert.color = "red darken-2"
                    that.alert.visiable = true
                })
            }
        },
        created(){
            this.getData()
            this.$emit("hideBackToTop")
        },
        watch: {
            'q.page': {
                handler: function() {
                 this.getData()
                }
            },
            'q.limit': {
                handler: function() {
                 this.getData()
                }
            },
        }
    }
</script>